<template>
  <div class="admin-dashboard__table v-card-table">
    <InputSearch v-model="searchText"/>
    <v-app>
      <v-card>
        <v-data-table
          item-key="userId"
          :headers="headers"
          :items="dashboardData"
          :page.sync="page"
          class="elevation-1"
          mobile-breakpoint="1"
          show-expand
          :loading="loading"
          :items-per-page="itemsPerPage"
          loading-text="Loading... Please wait"
          hide-default-footer
          single-expand
          :custom-sort="customSort"
          :expanded.sync="expanded"
          :search="searchText"
          @page-count="calculateCountPage()"
        >
          <template v-slot:header.type>
            <th>
              <v-select
                v-model="selectedChangeType[0]"
                :items="headerChangeType"
                item-text="text"
                item-value="value"
                class="select-type"
                solo
                @change="(type) => changedTypeInTable(type)"
              >
                <template v-slot:selection="{ item }">
                  <span class="select-type-text">
                    {{ item.text }}
                  </span>
                </template>
              </v-select>
            </th>
          </template>
          <template v-slot:item.selectGroup="{ item, index }">
            <v-select
              v-model="selectedGroup[index]"
              :items="item.itemsSelectGroups"
              item-text="groupName"
              item-value="id"
              class="select-group"
              @change="(groupId) => changeGroup(groupId, item.userId)"
            />
          </template>
          <template v-slot:item.save_pdf_file="{ item, index }">
            <v-btn
              :disabled="createPdf || !item.allType.userType"
              @click="saveCSVFile(item)"
              color="purple"
            >
              <span class="button-save-pdf-text">Save to PDF</span>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <ExpandTableRow
                :item="item"
                :subGroup="subGroup"
                :selectedGroup="selectedGroup"
                :myResultsScoreData="myResultsScoreData"
                :othersResultsScoreData="othersResultsScoreData"
                @changedFreeGroup="changedFreeGroup"
                @chooseOtherResult="chooseOtherResult"
              />
            </td>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :disabled="loading"
            :length="calculateCountPage()"
            :total-visible="10"
            @input="pageChange"
          ></v-pagination>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import ExpandTableRow from '@modules/AdminDashboard/ExpandTableRow.vue';
import InputSearch from '@modules/AdminDashboard/InputSearch.vue';
import constants from '@constants/index';
import pdf from '@helpers/createPDF';

export default {
  components: {
    ExpandTableRow,
    InputSearch,
  },
  props: {
    pageCount: {
      type: Number,
      default: 0,
    },
    dashboardData: {
      type: Array,
      default: () => {},
    },
    selectedGroup: {
      type: Array,
      default: () => [],
    },
    subGroup: {
      default: false,
    },
    myResultsScoreData: Object,
    othersResultsScoreData: Object,
    loading: Boolean,
  },
  data() {
    return {
      searchText: '',
      headers: [
        {
          text: '', value: 'expand', align: 'end', sortable: false,
        },
        {
          text: 'Employee', value: 'employee', align: 'start', width: 180,
        },
        {
          text: 'Created', value: 'created_date', align: 'center',
        },
        {
          text: 'Department', value: 'department', align: 'center',
        },
        {
          text: 'Role', value: 'role', align: 'center',
        },
        { text: 'Manager', value: 'manager', align: 'center' },
        {
          text: 'Consistency score overall',
          value: 'scoreOverall',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Connections',
          value: 'number_connections',
          align: 'center',
        },
        {
          text: 'Type',
          value: 'type',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Select Group',
          value: 'selectGroup',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Preferred Reviewer Ranking',
          value: 'preferred_reviewer_ranking',
          align: 'center',
        },
        {
          text: '',
          value: 'save_pdf_file',
          align: 'center',
          sortable: false,
        },
      ],
      page: 1,
      createPdf: false,
      itemsPerPage: 20,
      sortField: '',
      sortDesc: false,
      expanded: [],
      headerChangeType: [{ text: 'Type - User', value: 'u1' }, { text: 'Type - Colleagues', value: 'u2' }],
      selectedChangeType: [{ text: 'Type - User', value: 'u1' }],
    };
  },
  methods: {
    pageChange(currentPage) {
      this.page = currentPage;
      this.getDataDashboard();
    },
    calculateCountPage() {
      return Math.ceil(this.pageCount / this.itemsPerPage);
    },
    chooseOtherResult(options) {
      this.$emit('chooseOtherResult', { ...options, expanded: this.expanded });
    },
    saveCSVFile(item) {
      if (item.allType.userType) {
        this.createPdf = true;
        this.expanded.push(item);
        const nameForFileName = item.employee.replaceAll(' ', '_');
        const d = new Date();
        const createdDate = `${(`0${d.getDate()}`).slice(-2)}-${(`0${d.getMonth() + 1}`).slice(-2)}-${d.getFullYear()}`;
        const fullFileName = `${nameForFileName} ${createdDate} ${(`0${d.getHours()}`).slice(-2)}-${(`0${d.getMinutes()}`).slice(-2)}`;
        const title = `innerworks assessment for ${item.employee}, ${createdDate}`;
        pdf.delay(1000).then(async () => {
          await pdf.saveCSVFile(document.getElementById('chart-compare'),
            document.getElementById('bubble-chart'),
            constants.cards[item.allType.userType].showText, item.allType.userType,
            false, 20, 115, fullFileName, title, 18);
          this.createPdf = false;
          this.expanded = [];
        });
      }
    },
    changedFreeGroup(val, item) {
      this.$emit('changedFreeGroup', val, item);
    },
    changeGroup(groupId, userId) {
      this.$emit('changeGroup', groupId, userId);
    },
    getDataDashboard() {
      this.$emit('getDataDashboard', this.page, this.sortField, this.sortDesc);
    },
    changedTypeInTable(type) {
      const dashboardData = this.dashboardData.map(x => ({
        ...x,
        type: type === 'u1' ? x.allType.userType : x.allType.otherType,
      }));
      this.$emit('changeDashboardData', dashboardData);
    },
    customSort(items, index, isDesc) {
      if (index.length !== 0 && isDesc.length !== 0 && (this.sortField !== index[0]
        || this.sortDesc !== isDesc[0])) {
        // eslint-disable-next-line prefer-destructuring
        this.sortField = index[0];
        // eslint-disable-next-line prefer-destructuring
        this.sortDesc = isDesc[0];
        this.getDataDashboard();
      }
      if (index.length === 0 && this.sortField !== '') {
        this.sortDesc = false;
        this.sortField = '';
        this.getDataDashboard();
      }
      return items;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.admin-dashboard__table {
  .v-data-table-header {
    background-color: $tableColor1;
  }
  .v-data-footer {
    background-color: $tableColor1;
  }
  .v-pagination__item--active {
    background-color: $bgCardColor1 !important;
    border-color: $bgCardColor1 !important;
  }
  .vs--disabled .vs__dropdown-toggle,
  .vs--disabled .vs__clear,
  .vs--disabled .vs__search,
  .vs--disabled .vs__selected,
  .vs--disabled .vs__open-indicator {
    background-color: white;
  }
  .v-sheet .v-text-field__details {
    display: none;
  }
  td {
    font-family: Montserrat, sans-serif;
  }
  th span {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
  }
  tbody tr:nth-child(even) {
    background-color: $tableColor1;
  }
  tbody tr:nth-child(odd) {
    background-color: $tableColor2;
  }
  .select-group {
    width: 100%;
    max-width: 200px;
  }

  .v-small-dialog__content {
    height: 300px;
  }
  .select-type {
    width: 100%;
    max-width: 200px;
    margin-top: 7px !important;
  }
  .select-type .v-input__slot {
    background-color: $tableColor1 !important;
  }
  .select-type-text {
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
  .select-type .v-text-field__details {
    display: none;
  }
  .position-title-chart {
    margin-top: 11px;
  }
  .button-save-pdf-text {
    color: white;
  }
}
.v-card-table {
  margin: 20px;
}
</style>
